import axios from "axios";

import { bridgeUrl, matrixHomeserverDomain, matrixUrl } from "./apiHandle";

export class RoomHandler {
  constructor(type, id) {
    this.messages = [];
    this._type = type;
    this._id = id;
    this._start = undefined;
    this._end = undefined;
    this._events = [];
    this._updateTimer = 3000;
    this._interval = undefined;
    this._isScrollingBack = false;
    this._isUpdating = false;
    this._canScrollBack = false;
    this._onScrollBackCallback = undefined;
    this._onUpdateCallback = undefined;
  }

  onScrollBack(callback) {
    this._onScrollBackCallback = callback;
  }

  onUpdate(callback) {
    this._onUpdateCallback = callback;
  }

  async scrollBack() {
    if (!this._isScrollingBack && this._canScrollBack) {
      this._isScrollingBack = true;
      const response = await axios.get(bridgeUrl + "/message", {
        params: {
          roomType: this._type,
          roomId: this._id,
          from: this._start,
          direction: "b",
        },
      });
      if (this._start === response.data.end) {
        this._canScrollBack = false;
      }
      this._start = response.data.end;
      this._events.unshift(...response.data.messages.reverse());
      if (response.data.messages.length !== 0) {
        this.parseEvents();
      }
      if (response.data.messages.length !== 0 && this._onScrollBackCallback) {
        this._onScrollBackCallback();
      }
      this._isScrollingBack = false;
    }
  }

  async update() {
    if (!this._isUpdating) {
      this._isUpdating = true;
      const response = await axios.get(bridgeUrl + "/sync", {
        params: {
          roomType: this._type,
          roomId: this._id,
          from: this._end,
        },
      });
      this._end = response.data.end;
      this._events.push(...response.data.messages);
      if (response.data.start && response.data.start !== response.data.end) {
        this._start = response.data.start;
        this._canScrollBack = true;
      }
      if (response.data.messages.length !== 0) {
        this.parseEvents();
      }
      if (response.data.messages.length !== 0 && this._onUpdateCallback) {
        this._onUpdateCallback();
      }
      this._isUpdating = false;
    }
  }

  async sendMessage(message) {
    const transactionId = String(Math.floor(Math.random() * 60000));
    await axios.put(bridgeUrl + "/message", {
      roomType: this._type,
      roomId: this._id,
      message,
      transactionId,
    });
    await this.update();
  }

  async editMessage(eventId, newMessage) {
    const transactionId = String(Math.floor(Math.random() * 60000));
    await axios.put(bridgeUrl + "/message", {
      roomType: this._type,
      roomId: this._id,
      message: {
        msgtype: newMessage.msgtype,
        body: "* " + newMessage.body,
        "m.relates_to": {
          rel_type: "m.replace",
          event_id: eventId,
        },
        "m.new_content": newMessage,
      },
      transactionId,
    });
    await this.update();
  }

  async deleteMessage(eventId) {
    const transactionId = String(Math.floor(Math.random() * 60000));
    await axios.put(bridgeUrl + "/delete", {
      roomType: this._type,
      roomId: this._id,
      eventId,
      transactionId,
    });
    await this.update();
  }

  enablePeriodicUpdates() {
    if (!this._interval) {
      this._interval = setInterval(this.update.bind(this), this._updateTimer);
    }
  }

  disablePeriodicUpdates() {
    if (this._interval) {
      clearInterval(this._interval);
      delete this._interval;
    }
  }

  mxcToHttps(mxcUrl) {
    if (mxcUrl.startsWith("mxc:")) {
      return (
        matrixUrl +
        "/_matrix/media/r0/download/" +
        matrixHomeserverDomain +
        "/" +
        mxcUrl.split("/").at(-1)
      );
    }
    return mxcUrl;
  }

  parseEvents() {
    const messages = [];
    for (const event of this._events) {
      if ("redacts" in event) {
        const index = messages.findIndex((e) => e.eventId === event.redacts);
        if (index !== -1) {
          messages.splice(index, 1);
        }
      } else if ("content" in event) {
        if (
          "m.relates_to" in event.content &&
          event.content["m.relates_to"].rel_type === "m.replace"
        ) {
          const index = messages.findIndex(
            (e) => e.eventId === event.content["m.relates_to"].event_id
          );
          if (index !== -1) {
            messages[index].content = event.content["m.new_content"];
          }
        } else {
          messages.push(event);
        }
      }
    }
    this.messages = messages;
  }
}
