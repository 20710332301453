import { RoomHandler } from "./roomHandler";

export class RoomRepository {
  constructor() {
    this._rooms = {};
  }

  getRoom(type, id) {
    const candidate = this._rooms[JSON.stringify({ type, id })];
    if (candidate) {
      return candidate;
    }
    const room = new RoomHandler(type, id);
    this._rooms[JSON.stringify({ type, id })] = room;
    return room;
  }
}

const roomRepository = new RoomRepository();

export function getRoomRepository() {
  return roomRepository;
}
